$(document).ready(function() {
  var owl = $('.together-cheaper-owl-carousel').owlCarousel({
    items: 1,
    loop: false,
    margin: 50,
    nav: true,
    navContainer: '.owl-nav-container',
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>'
    ],
  });
  owl.on('changed.owl.carousel', function(event) {
    recalculatePrice();
  });
  var height = $('.together-cheaper-product').height(),
  indexes = [],
  count = [];
  $('.together-cheaper-product-carousel').map(function(index, item) {
    $(item).attr('data-index', index);
    indexes.push(0);
    var itemCount = $(item).find('.together-cheaper-product').length - 1
    count.push(itemCount);
  });
  console.log(indexes)
  console.log(count)
  $('.together-cheaper-product-carousel-inner').css('height', height);
  recalculatePrice();
  function moveTop (index) {
    if (indexes[index] > 0) {
      indexes[index]--;
    }
    moveSlides(index);
    toggleDisableClassOnBtn(index);
    addActiveClassToItem(index);
    recalculatePrice();
  }
  function moveBottom (index) {
    if (count[index] > indexes[index]) {
      indexes[index]++;
    }
    moveSlides(index);
    toggleDisableClassOnBtn(index);
    addActiveClassToItem(index);
    recalculatePrice();
  }

  function moveSlides(index) {
    $('.together-cheaper-product-carousel[data-index="' + index + '"] .scroller').css('transform', 'translateY(-' + height * indexes[index]  + 'px)');
  }

  function toggleDisableClassOnBtn (index) {
    $('.together-cheaper-product-carousel[data-index="' + index + '"] .together-cheaper-product-carousel-nav').removeClass('disabled');
    if (indexes[index] === 0) {
      $('.together-cheaper-product-carousel[data-index="' + index + '"] .together-cheaper-product-carousel-nav-top').addClass('disabled');
    }
    if (indexes[index] === count[index]) {
      $('.together-cheaper-product-carousel[data-index="' + index + '"] .together-cheaper-product-carousel-nav-bottom').addClass('disabled');
    }
  }
  function addActiveClassToItem (index) {
    var activeItem = $('.together-cheaper-product-carousel[data-index="' + index + '"] .together-cheaper-product')[indexes[index]];
    $('.together-cheaper-product-carousel[data-index="' + index + '"] .together-cheaper-product').removeClass('active');
    $(activeItem).addClass('active');
  }

  function recalculatePrice () {
    var price = 0,
    discount = 0;
    $('.owl-item.active .together-cheaper-product.active .price').map(function (index, item) {
      price = price + +$(item).data('price').replace(',', '.');
    })
    $('.owl-item.active .together-cheaper-product.active .discount').map(function (index, item) {
      discount = discount + +$(item).data('discount');
    })
    
    $('.discount-right strong').html(discount.toFixed(2));
    $('.price-right strong').html(price.toFixed(2));
  }

  $('.together-cheaper-product-carousel-nav-top').click(function(e) {
    if(!$(this).hasClass('disabled')) {
      var curIndex = $(this).parents('.together-cheaper-product-carousel').data("index");
      moveTop(curIndex);
    }
  });
  $('.together-cheaper-product-carousel-nav-bottom').click(function(e) {
    if(!$(this).hasClass('disabled')) {
      var curIndex = $(this).parents('.together-cheaper-product-carousel').data("index");
      moveBottom(curIndex);
    }
  });
});